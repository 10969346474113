/** @deprecated will be deprecated when we switch cms to new endpoints */
export type CmsActionButtonOld = {
  readonly text: string;
  readonly kind: 'register' | 'deposit' | 'link';
  readonly url: string | undefined;
};

export function createCmsActionButton(json: Record<string, any>): CmsActionButtonOld {
  if (json['payload'].includes('#modal-register')) {
    return {
      text: json['label'],
      kind: 'register',
      url: undefined
    };
  }

  if (json['payload'].includes('#modal-deposit')) {
    return {
      text: json['label'],
      kind: 'deposit',
      url: undefined
    };
  }

  return {
    text: json['label'],
    kind: json['type'],
    url: json['payload']
  };
}

export function createCmsActionButtonData(jsons: Record<string, any>[]): CmsActionButtonOld[] {
  return jsons.map(createCmsActionButton);
}
