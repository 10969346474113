export function parseRawError(error: any): Error {
  try {
    return {
      name: error.name,
      message: error.message,
      stack: error.stack
    };
  } catch (_err) {
    return error;
  }
}
