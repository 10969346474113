import { TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { CSSResultGroup, TemplateResult, html } from 'lit';
import { property } from 'lit/decorators.js';
import {
  TextAlignKind,
  TypographyColorKind,
  TypographyFontFamily,
  TypographySizeKind,
  TypographyVariantKind
} from './types';
import { typographyStyles } from './typography.styles';

export class TypographyElement extends TmfLitElement {
  public static override elementSelector: string = 'tmfds-typography';
  public static override styles: CSSResultGroup = typographyStyles();

  @property({ type: String })
  public variant: TypographyVariantKind = 'body';

  @property({ type: Number })
  public size?: TypographySizeKind;

  @property({ type: String })
  public color?: TypographyColorKind;

  @property({ type: String })
  public textAlign?: TextAlignKind;

  @property({ type: String })
  public fontFamily?: TypographyFontFamily = 'poppins';

  @property({ type: Boolean })
  public ellipsis?: boolean = false;

  @property({ type: Boolean })
  public uppercase?: boolean = false;

  private _currentCssClasses: string | undefined;

  constructor() {
    super();
    this._registerStylesFroSnapshots(TypographyElement);
  }

  private _updateCssClasses(): void {
    this._currentCssClasses ??= this.className;
    const cssClasses: (string | undefined)[] = [
      this.variant,
      this.size ? `fs-${this.size}` : undefined,
      this.color,
      this.textAlign,
      `font-${this.fontFamily}`,
      this.ellipsis ? 'ellipsis' : undefined,
      this.uppercase ? 'uppercase' : undefined
    ];

    this.className = `${this._currentCssClasses} ${cssClasses.filter(Boolean).join(' ')}`;
  }

  public override render(): TemplateResult {
    this._updateCssClasses();

    return html`<slot></slot>`;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmfds-typography': TypographyElement;
  }
}
