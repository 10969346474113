import { CSSResult, CSSResultGroup, css } from 'lit';
import { genAlertIconCommonStyles } from './alert-icon.common.styles';
export function genAlertIconMobileStyle(): CSSResult {
  return css`
    .box-icon {
      width: 56px;
      height: 56px;
    }

    .icon {
      --icon-width: 24px;
      --icon-height: 24px;
    }

    .icon-exclamation {
      --icon-width: 5px;
    }

    .icon-check {
      --icon-width: 21px;
    }

    .icon-map {
      --icon-width: 24px;
    }

    .icon-face-disappointed {
      --icon-width: 48px;
    }

    .heading {
      margin-bottom: var(--space-3);
      font-size: 16px;
    }

    .body {
      font-size: 14px;
    }
  `;
}

export const alertIconStylesMobile = (): CSSResultGroup => {
  return [genAlertIconCommonStyles(), genAlertIconMobileStyle()];
};
