import { entriesTyped } from '@bcf-vanilla-ts-v1-shared/misc/pure-utils/entries-typed';
import { CSSResult, CSSResultGroup, css, unsafeCSS } from 'lit';
import {
  TypographyColorConfig,
  TypographyFontSizeConfig,
  TypographyLineHeightConfig,
  TypographyTextAlignConfig,
  TypographyVariantConfig
} from './style.types';
import { TextAlignKind, TypographyColorKind, TypographySizeKind, TypographyVariantKind } from './types';

const typographySizesConfig: Record<TypographySizeKind, TypographyFontSizeConfig> = {
  '36': { fontSize: unsafeCSS(`36px`) },
  '32': { fontSize: unsafeCSS(`32px`) },
  '24': { fontSize: unsafeCSS(`24px`) },
  '20': { fontSize: unsafeCSS(`20px`) },
  '18': { fontSize: unsafeCSS(`18px`) },
  '16': { fontSize: unsafeCSS(`16px`) },
  '14': { fontSize: unsafeCSS(`14px`) },
  '13': { fontSize: unsafeCSS(`13px`) },
  '12': { fontSize: unsafeCSS(`12px`) },
  '10': { fontSize: unsafeCSS(`10px`) },
  '8': { fontSize: unsafeCSS(`8px`) }
};

const typographyLineHeightConfig: Record<TypographySizeKind, TypographyLineHeightConfig> = {
  '36': { bodyLineHeight: unsafeCSS(1), titleLineHeight: unsafeCSS(1), headingLineHeight: unsafeCSS('43px') },
  '32': { bodyLineHeight: unsafeCSS(1), titleLineHeight: unsafeCSS(1), headingLineHeight: unsafeCSS(1) },
  '24': { bodyLineHeight: unsafeCSS('36px'), titleLineHeight: unsafeCSS('29px'), headingLineHeight: unsafeCSS('29px') },
  '20': { bodyLineHeight: unsafeCSS('30px'), titleLineHeight: unsafeCSS('24px'), headingLineHeight: unsafeCSS('24px') },
  '18': { bodyLineHeight: unsafeCSS('27px'), titleLineHeight: unsafeCSS('22px'), headingLineHeight: unsafeCSS('22px') },
  '16': { bodyLineHeight: unsafeCSS('24px'), titleLineHeight: unsafeCSS('19px'), headingLineHeight: unsafeCSS('19px') },
  '14': { bodyLineHeight: unsafeCSS('21px'), titleLineHeight: unsafeCSS('17px'), headingLineHeight: unsafeCSS('17px') },
  '13': { bodyLineHeight: unsafeCSS(1), titleLineHeight: unsafeCSS(1), headingLineHeight: unsafeCSS(1) },
  '12': { bodyLineHeight: unsafeCSS('18px'), titleLineHeight: unsafeCSS('18px'), headingLineHeight: unsafeCSS('18px') },
  '10': { bodyLineHeight: unsafeCSS('15px'), titleLineHeight: unsafeCSS('12px'), headingLineHeight: unsafeCSS('12px') },
  '8': { bodyLineHeight: unsafeCSS('12px'), titleLineHeight: unsafeCSS('10px'), headingLineHeight: unsafeCSS('10px') }
};

const typographyVariantsConfig: Record<TypographyVariantKind, TypographyVariantConfig> = {
  heading: { fontWeight: unsafeCSS(`initial`), fontFamily: unsafeCSS(`paytone-one`) },
  title: { fontWeight: unsafeCSS(`700`) },
  body: { fontWeight: unsafeCSS(`400`) }
};

const typographyColorsConfig: Record<TypographyColorKind, TypographyColorConfig> = {
  brand: { color: unsafeCSS(`var(--text-brand)`) },
  accent: { color: unsafeCSS(`var(--text-accent)`) },
  primary: { color: unsafeCSS(`var(--text-primary)`) },
  'primary-inverted': { color: unsafeCSS(`var(--text-primary-inverse)`) },
  success: { color: unsafeCSS(`var(--text-state-success)`) },
  info: { color: unsafeCSS(`var(--text-state-info)`) },
  warning: { color: unsafeCSS(`var(--text-state-warning)`) },
  error: { color: unsafeCSS(`var(--text-state-error)`) },
  secondary: { color: unsafeCSS(`var(--text-secondary)`) },
  tertiary: { color: unsafeCSS(`var(--text-tertiary)`) },
  quaternary: { color: unsafeCSS(`var(--text-quaternary)`) },
  link: { color: unsafeCSS(`var(--text-link)`) },
  'link-hover': { color: unsafeCSS(`var(--text-link-hover)`) },
  'link-disabled': { color: unsafeCSS(`var(--text-link-disabled)`) },
  'on-dark-image': { color: unsafeCSS(`var(--text-on-dark-image)`) },
  'on-light-image': { color: unsafeCSS(`var(--text-on-light-image)`) }
};

const typographyTextAlignConfig: Record<TextAlignKind, TypographyTextAlignConfig> = {
  left: { textAlign: unsafeCSS(`left`) },
  right: { textAlign: unsafeCSS(`right`) },
  center: { textAlign: unsafeCSS(`center`) },
  justify: { textAlign: unsafeCSS(`justify`) }
};

function genBaseTypographyStyles(): CSSResult {
  return css`
    :host {
      display: block;
      margin: 0;
      padding: 0;
      letter-spacing: -0.02em;
      -webkit-tap-highlight-color: transparent;
      box-sizing: border-box;
    }

    :host(.ellipsis) {
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    :host(.uppercase) {
      text-transform: uppercase;
    }

    ::slotted(a) {
      color: var(--brand-50);
      font-weight: 700;
    }
  `;
}

function genTypographyVariantStyles([variant, config]: [TypographyVariantKind, TypographyVariantConfig]): CSSResult {
  let style: CSSResult = css`
    :host(.${unsafeCSS(variant)}) {
      font-weight: ${config.fontWeight};
    }
  `;

  if (config.fontFamily) {
    style = css`
      ${style}
      :host(.${unsafeCSS(variant)}) {
        font-family: ${unsafeCSS(`var(--font-family-${config.fontFamily})`)};
      }
    `;
  }

  return style;
}

function genTypographySizeStyles([size, config]: [TypographySizeKind, TypographyFontSizeConfig]): CSSResult {
  return css`
    :host(.fs-${unsafeCSS(size)}) {
      font-size: ${config.fontSize};
    }
  `;
}

function genTypographyLineHeightStyles([size, config]: [TypographySizeKind, TypographyLineHeightConfig]): CSSResult {
  return css`
    :host(.fs-${unsafeCSS(size)}.body) {
      line-height: ${config.bodyLineHeight};
    }
    :host(.fs-${unsafeCSS(size)}.title) {
      line-height: ${config.titleLineHeight};
    }
    :host(.fs-${unsafeCSS(size)}.heading) {
      line-height: ${config.headingLineHeight};
    }
  `;
}

function genTypographyColorStyles([variant, config]: [TypographyColorKind, TypographyColorConfig]): CSSResult {
  return css`
    :host(.${unsafeCSS(variant)}) {
      color: ${config.color};
    }
  `;
}

function genTypographyTextAlignStyles([textAlign, config]: [TextAlignKind, TypographyTextAlignConfig]): CSSResult {
  return css`
    :host(.${unsafeCSS(textAlign)}) {
      text-align: ${config.textAlign};
    }
  `;
}

export const typographyStyles = (): CSSResultGroup => {
  return [
    genBaseTypographyStyles(),
    ...entriesTyped<TypographyVariantKind, TypographyVariantConfig>(typographyVariantsConfig).map(
      genTypographyVariantStyles
    ),
    ...entriesTyped<TypographySizeKind, TypographyFontSizeConfig>(typographySizesConfig).map(genTypographySizeStyles),
    ...entriesTyped<TypographyColorKind, TypographyColorConfig>(typographyColorsConfig).map(genTypographyColorStyles),
    ...entriesTyped<TypographySizeKind, TypographyLineHeightConfig>(typographyLineHeightConfig).map(
      genTypographyLineHeightStyles
    ),
    ...entriesTyped<TextAlignKind, TypographyTextAlignConfig>(typographyTextAlignConfig).map(
      genTypographyTextAlignStyles
    )
  ];
};
