import { provideDiTokenValue } from '@bcf-vanilla-ts-v1-shared/di/di-token';
import { isBrowser } from '@bcf-vanilla-ts-v1-shared/misc/utils/is-ssr';
import { ENV_COMMON } from '@bcflit-env/env-token';
import { EnvCommon } from '@bcflit-env/types';
import { TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { rxDone } from '@tmf-shared-misc/rx-helpers';
import { filterIsTruthy } from '@tmf-shared-misc/rx-operators';
import { CSSResultGroup, TemplateResult, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { ReplaySubject, Subject, combineLatest, distinctUntilChanged, map, takeUntil } from 'rxjs';
import { SvgIconName } from './icons-types';
import { provideSvgIconAppComputedStylesService } from './svg-icon-app-computed-styles.service';
import {
  SvgIconInheritedComputedStylesService,
  provideSvgIconInheritedComputedStylesService
} from './svg-icon-inherited-computed-styles';
import { SvgIconLoader, provideSvgIconLoader } from './svg-icon-loader';
import { svgIconStyles } from './svg-icon.styles';
import { SvgIconFill1Color, SvgIconFill2Color } from './types';
import { getParsedImgSvgPath } from './utils/get-parsed-img-svg-path';
import { getValueFromComputedStyles } from './utils/get-value-from-computed-styles';

export class SvgIconElement extends TmfLitElement {
  public static override elementSelector: string = 'tmfds-svg-icon';
  public static override styles: CSSResultGroup = svgIconStyles();

  @property({ type: String })
  public name!: SvgIconName;

  @property({ type: String, attribute: 'fill-1' })
  public fill1?: SvgIconFill1Color;

  @property({ type: String, attribute: 'fill-2' })
  public fill2?: SvgIconFill2Color;

  @property({ type: Number })
  public width?: number;

  @property({ type: Number })
  public height?: number;

  @state()
  private _imageElement: HTMLImageElement | undefined;

  @state()
  private _imageSsrSrc: string | undefined;

  private _svgIconInheritedComputedStylesService: SvgIconInheritedComputedStylesService =
    provideSvgIconInheritedComputedStylesService();
  private _svgIconInheritedComputedStylesServiceId: string | undefined;
  private _svgIconLoader: SvgIconLoader = provideSvgIconLoader();

  private _name$: Subject<string> = new ReplaySubject<string>();
  private _fill1$: ReplaySubject<SvgIconFill1Color | undefined> = new ReplaySubject<SvgIconFill1Color | undefined>(1);
  private _fill2$: ReplaySubject<SvgIconFill2Color | undefined> = new ReplaySubject<SvgIconFill2Color | undefined>(1);
  private _fill1Extracted$: ReplaySubject<string | undefined> = new ReplaySubject<string | undefined>(1);
  private _fill2Extracted$: ReplaySubject<string | undefined> = new ReplaySubject<string | undefined>(1);
  private _destroy$: Subject<void> = new Subject<void>();

  private _currentCssClasses: undefined | string;
  private _computedStyles: CSSStyleDeclaration | undefined;

  private _envCommon: EnvCommon = provideDiTokenValue(ENV_COMMON);

  public override connectedCallback(): void {
    super.connectedCallback();
    combineLatest([
      this._name$.pipe(distinctUntilChanged()),
      this._fill1$.pipe(distinctUntilChanged()),
      this._fill2$.pipe(distinctUntilChanged()),
      this._fill1Extracted$.pipe(distinctUntilChanged()),
      this._fill2Extracted$.pipe(distinctUntilChanged())
    ])
      .pipe(
        map(
          ([name, fill1, fill2, fill1Extracted, fill2Extracted]: [
            string,
            SvgIconFill1Color | undefined,
            SvgIconFill2Color | undefined,
            string | undefined,
            string | undefined
          ]) =>
            getParsedImgSvgPath(
              this._envCommon.svgIconConfig,
              provideSvgIconAppComputedStylesService(),
              name,
              fill1,
              fill2,
              fill1Extracted,
              fill2Extracted
            )
        ),
        filterIsTruthy(),
        distinctUntilChanged(),
        takeUntil(this._destroy$)
      )
      .subscribe((svgIconPath: string) => {
        if (!isBrowser()) {
          this._imageSsrSrc = this._svgIconLoader.getImageSrc(svgIconPath, this._envCommon.svgIconConfig);
          return;
        }
        if (this._imageElement) {
          // unlock previous image
          this._svgIconLoader.unlock(this._imageElement);
        }
        this._imageElement = this._svgIconLoader.loadAndLock(this.name, svgIconPath, this._envCommon.svgIconConfig);
      });
    if (isBrowser()) {
      this._computedStyles = getComputedStyle(this);
    }

    this._svgIconInheritedComputedStylesServiceId = this._svgIconInheritedComputedStylesService.observeInInterval(
      () => {
        this._fill1Extracted$.next(getValueFromComputedStyles(this._computedStyles, `--fill-1`));
        this._fill2Extracted$.next(getValueFromComputedStyles(this._computedStyles, `--fill-2`));
      }
    );
  }

  public override disconnectedCallback(): void {
    super.disconnectedCallback();
    rxDone(this._destroy$);
    if (this._svgIconInheritedComputedStylesServiceId) {
      this._svgIconInheritedComputedStylesService.destroy(this._svgIconInheritedComputedStylesServiceId);
    }
    if (this._imageElement) {
      this._svgIconLoader.unlock(this._imageElement);
    }
  }

  private _updateCssClasses(): void {
    this._currentCssClasses ??= this.className ?? '';

    this.className = [this._currentCssClasses, 'icon', `icon-${this.name}`]
      .filter((cssClass: string) => cssClass.length > 0)
      .join(' ');
  }

  private _updateStylesClasses(): void {
    const element: HTMLImageElement | undefined = this._imageElement;

    if (!element) {
      return;
    }

    if (this.height) {
      element.style.height = `${this.height}px`;
      element.style.width = `auto`;
      return;
    }
    if (this.width) {
      element.style.height = `auto`;
      element.style.width = `${this.width}px`;
    }
    return;
  }

  public override render(): TemplateResult {
    this._name$.next(this.name);
    this._fill1$.next(this.fill1);
    this._fill2$.next(this.fill2);
    this._updateCssClasses();
    if (this._imageSsrSrc) {
      return html`<img src="${this._imageSsrSrc}" />`;
    }
    if (this._imageElement) {
      this._updateStylesClasses();
      return html`${this._imageElement}`;
    } else {
      return html``;
    }
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmfds-svg-icon': SvgIconElement;
  }
}
