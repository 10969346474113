import { device } from '@bcflit-device';
import { CSSResultGroup, css } from 'lit';

function desktopStyles(): CSSResultGroup {
  return css`
    :host {
      height: 100vh;
    }

    .image {
      width: 480px;
      padding-top: var(--space-8);
    }

    .icon-logo {
      --icon-width: 144px;
    }

    .heading {
      font-size: 36px;
    }
  `;
}

function mobileStyles(): CSSResultGroup {
  return css`
    :host {
      overflow: hidden;
      z-index: 12;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      min-height: 100%;
      box-sizing: border-box;
    }

    .image {
      width: 320px;
      padding-top: var(--space-3);
    }

    .icon-logo {
      --icon-width: 108px;
    }

    .heading {
      font-size: 24px;
    }
  `;
}

export function maintenanceStyles(): CSSResultGroup {
  return [
    device === 'mobile' ? mobileStyles() : desktopStyles(),
    css`
      :host {
        display: flex;
        flex-flow: column;
        justify-content: center;
        padding: var(--space-4);
        box-sizing: border-box;
      }
      .image {
        display: block;
      }
      .box-icon {
        display: none;
      }
    `
  ];
}
