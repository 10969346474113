export function getValueFromComputedStyles(
  computedStyles: CSSStyleDeclaration | undefined,
  cssProperty: string
): string | undefined {
  if (!computedStyles) {
    return undefined;
  }
  const result: string = computedStyles.getPropertyValue(cssProperty);
  if (result.length === 0) {
    return undefined;
  }
  return result;
}
