import { BcfLitElement } from '@bcflit-v1-ui-shared/classes/bcf-lit-element';

function camelToKebab(property: string): string {
  return property.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

type StyleKey = keyof CSSStyleDeclaration;
type StyleValue = CSSStyleDeclaration[StyleKey];

export class ElementStyleApi {
  private _memory: Map<StyleKey, StyleValue> = new Map<StyleKey, StyleValue>();

  constructor(private _element: BcfLitElement) {}

  public setStyle<K extends keyof CSSStyleDeclaration, V extends CSSStyleDeclaration[K]>(key: K, value: V): void {
    this._memory.set(key, value);
  }

  public update(): void {
    this._readCurrentStyles();
    const container: string[] = [];
    for (const [key, value] of this._memory.entries()) {
      if (value !== undefined && value?.toString() && value.toString()?.length > 0) {
        container.push(`${camelToKebab(key.toString())}: ${value}`);
      }
    }
    if (container.length > 0) {
      this._element.setAttribute('style', `${container.join('; ')};`);
    } else {
      this._element.removeAttribute('style');
    }
  }

  private _readCurrentStyles(): void {
    if (!this._element.style) {
      return;
    }
    for (const [key, value] of Object.entries(this._element.style)) {
      if (value && value.length > 0 && isNaN(Number(key))) {
        this._memory.set(key as StyleKey, value);
      }
    }
  }
}
