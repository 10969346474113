import { TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { CSSResult, TemplateResult, css, html } from 'lit';
import { property } from 'lit/decorators.js';
import { ElementStyleApi } from '../element-style-api';

type Gap = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19;
type Padding = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19;
type Wrap = 'nowrap' | 'wrap' | 'wrap-reverse';
type AlignItems = 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
type Justify = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';

export class ColumnElement extends TmfLitElement {
  public static override elementSelector: string = 'tmfds-column';

  public static override styles: CSSResult = css`
    :host {
      display: flex;
      flex-flow: column;
      gap: var(--column-gap);
      -webkit-tap-highlight-color: transparent;
      box-sizing: border-box;
    }
  `;

  @property({ type: Number })
  public gap?: Gap;

  @property({ type: Number })
  public padding?: Padding;

  @property({ type: Number })
  public paddingX?: Padding;

  @property({ type: Number })
  public paddingY?: Padding;

  @property({ type: String })
  public wrap?: Wrap;

  @property({ type: String })
  public alignItems: AlignItems = 'stretch';

  @property({ type: String })
  public justify: Justify = 'flex-start';

  public override connectedCallback(): void {
    super.connectedCallback();
    this._updateStyles();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected override updated(): void {
    this._updateStyles();
  }

  private _updateStyles(): void {
    const styleApi: ElementStyleApi = new ElementStyleApi(this);
    styleApi.setStyle('gap', this.gap ? `var(--space-${this.gap})` : '');
    styleApi.setStyle('padding', this._getPaddingStyle());
    styleApi.setStyle('justifyContent', this.justify);
    styleApi.setStyle('alignItems', this.alignItems);
    if (this.wrap) {
      styleApi.setStyle('flexWrap', this.wrap);
    }
    styleApi.update();
  }

  private _getPaddingStyle(): string {
    if (this.padding === undefined && this.paddingX === undefined && this.paddingY === undefined) {
      return '';
    }

    const xPadding: string = this.paddingX ? `var(--space-${this.paddingX})` : '0';
    const yPadding: string = this.paddingY ? `var(--space-${this.paddingY})` : '0';
    const allPadding: string = this.padding ? `var(--space-${this.padding})` : '';

    if (this.padding !== undefined) {
      return allPadding;
    } else {
      return `${yPadding} ${xPadding}`;
    }
  }

  public override render(): TemplateResult {
    return html`<slot></slot>`;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmfds-column': ColumnElement;
  }
}
