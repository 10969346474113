import { CmsActionButtonOld } from './cms-action-button';

export type CmsPromotionDetail = {
  readonly title: string;
  readonly categories: string[];
  readonly imageUrl: string;
  readonly bonusText: string | undefined;
  readonly categoryText: string | undefined;
  readonly timeText: string | undefined;
  readonly textHtml: string;
  readonly campaignId: string | undefined;
  readonly termsHtml: string;
  readonly termsAfterText: string | undefined;
  readonly actionButton: CmsActionButtonOld;
};

function createActionButton(json: Record<string, any>): CmsActionButtonOld {
  return {
    text: json['label'],
    kind: json['type'],
    url: json['btnURL'] ?? undefined
  };
}

export function createCmsPromotionDetailFromHttpData(httpData: Record<string, any>): CmsPromotionDetail | undefined {
  if (!httpData['data'] || (httpData['data'] && httpData['data'].length === 0)) {
    return undefined;
  }
  const data: Record<string, any> = httpData['data'][0]['attributes'];
  return {
    title: data['title'],
    categories: data['categories']['data'].map((inner: Record<string, any>) => inner['attributes']['categoryName']),
    imageUrl: data['imageUrl']['data']['attributes']['url'],
    bonusText: data['promotionDetails'][0]['bonusText'],
    categoryText: data['promotionDetails'][0]['categoryText'],
    timeText: data['promotionDetails'][0]['time'],
    textHtml: data['promotionDetails'][0]['textHtml'],
    campaignId: data['campaignId'] && data['campaignId'].length > 0 ? data['campaignId'] : undefined,
    termsHtml: data['terms'][0]['plainHTML'],
    termsAfterText: data['terms'][0]['uniqueTerms'],
    actionButton: createActionButton(data['buttons'][0])
  };
}
