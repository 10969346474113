import { ModalKind } from '@bcflit-v1-ui-modals-shared/types';

function checkActivateSegment(): void {
  if (window.location.pathname.includes('/activate/')) {
    const splited: string[] = window.location.pathname.split('/');
    if (splited.length !== 5) {
      return;
    }
    const code: string = splited[splited.length - 2];
    const modalActivateAccount: ModalKind = 'activate-account';
    const newUrl: string = `/${splited[1]}/#modal-${modalActivateAccount}$${code}`;
    window.location.href = newUrl;
  }
}

function checkTrailingSlash(): void {
  if (!window.location.pathname.endsWith('/')) {
    const newUrl: string = `${window.location.pathname}/${window.location.search}${window.location.hash}`;
    window.location.href = newUrl;
  }
}

export function appInitRedirector(): void {
  checkActivateSegment();
  checkTrailingSlash();
}
