import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { isBrowser } from '@bcf-vanilla-ts-v1-shared/misc/utils/is-ssr';
import { random } from 'rambdax-v11';

type Callback = () => void;

export class SvgIconInheritedComputedStylesService {
  private _callBacks: Map<string, Callback> = new Map<string, Callback>();

  constructor() {
    /** we run one interval for whole app
     * we need this to check if inherited css variables changed
     */

    if (isBrowser()) {
      setInterval(() => {
        this._runCallbacks();
      }, 100);
      document.body.addEventListener('click', () => this._runCallbacks());
    }
  }

  private _runCallbacks(): void {
    for (const cb of this._callBacks.values()) {
      cb();
    }
  }

  public observeInInterval(callBackToRegister: Callback): string {
    const id: string = `${new Date().getTime()}~${random(0, 1000)}`;
    this._callBacks.set(id, callBackToRegister);
    callBackToRegister();
    return id;
  }

  public destroy(id: string): void {
    this._callBacks.delete(id);
  }
}

export function provideSvgIconInheritedComputedStylesService(): SvgIconInheritedComputedStylesService {
  return provideSingleton(SvgIconInheritedComputedStylesService, () => new SvgIconInheritedComputedStylesService());
}
