import { CmsActionButtonOld, createCmsActionButtonData } from './cms-action-button';

export type CmsWidgetMainBanner = {
  readonly badgeTagTitle: string;
  readonly typoHeading: string;
  readonly typoBodyHtml: string;
  readonly imageUrl: string;
  readonly contentPosition: 'left' | 'center';
  readonly shortTerms: string | undefined;
  readonly actionButton: CmsActionButtonOld[] | undefined;
  readonly _type: '_CmsWidgetMainBanner';
};

export function isCmsWidgetMainBanner(input: unknown): input is CmsWidgetMainBanner {
  return (input as any)?.['_type'] === '_CmsWidgetMainBanner';
}

export function createCmsWidgetMainBannerData(json: Record<string, any>): CmsWidgetMainBanner {
  return {
    badgeTagTitle: json['label'] ?? undefined,
    typoHeading: json['title'],
    typoBodyHtml: json['plainHtml'] ?? undefined,
    imageUrl: json['image'],
    contentPosition: json['contentPosition'],
    shortTerms: json['shortTerms'],
    actionButton: json['buttons'] ? createCmsActionButtonData(json['buttons']) : undefined,
    _type: '_CmsWidgetMainBanner'
  };
}
