import { ImportedElement, TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { MaintenanceElement } from '@tmf-ui-misc-casino-components-janusz-casino-mobile/maintenance/maintenance.element';
import { TemplateResult, html } from 'lit';

export class MaintenancePageElement extends TmfLitElement {
  public static override elementSelector: string = 'tmf-jzm-maintenance-page';
  public override _importedElements: ImportedElement[] = [MaintenanceElement];

  public override render(): TemplateResult {
    return html`<tmf-jzm-maintenance></tmf-jzm-maintenance>`;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmf-jzm-maintenance-page': MaintenancePageElement;
  }
}
