import { entriesTyped } from '@bcf-vanilla-ts-v1-shared/misc/pure-utils/entries-typed';
import { CSSResult, CSSResultGroup, css, unsafeCSS } from 'lit';
import { ButtonSizeStylesConfig, ButtonVariantStylesConfig } from './styles-types';
import { ButtonSizeKind, ButtonVariantKind } from './types';

const buttonSizesConfig: Record<ButtonSizeKind, ButtonSizeStylesConfig> = {
  xlarge: {
    fs: unsafeCSS(`20px`),
    px: unsafeCSS(`var(--space-6)`),
    size: unsafeCSS(`56px`),
    radius: unsafeCSS(`56px`),
    iconSize: unsafeCSS(`20px`),
    iconMargin: unsafeCSS(`var(--space-2)`)
  },
  large: {
    fs: unsafeCSS(`18px`),
    px: unsafeCSS(`var(--space-6)`),
    size: unsafeCSS(`48px`),
    radius: unsafeCSS(`48px`),
    iconSize: unsafeCSS(`18px`),
    iconMargin: unsafeCSS(`var(--space-2)`)
  },
  medium: {
    fs: unsafeCSS(`16px`),
    px: unsafeCSS(`var(--space-6)`),
    size: unsafeCSS(`40px`),
    radius: unsafeCSS(`24px`),
    iconSize: unsafeCSS(`16px`),
    iconMargin: unsafeCSS(`var(--space-2)`)
  },
  small: {
    fs: unsafeCSS(`14px`),
    px: unsafeCSS(`var(--space-4)`),
    size: unsafeCSS(`32px`),
    radius: unsafeCSS(`16px`),
    iconSize: unsafeCSS(`14px`),
    iconMargin: unsafeCSS(`var(--space-1)`)
  }
};

const buttonVariantsConfig: Record<ButtonVariantKind, ButtonVariantStylesConfig> = {
  primary: {
    bg: unsafeCSS(`var(--brand-50)`),
    bgActive: unsafeCSS(`var(--brand-70)`),
    bgHover: unsafeCSS(`var(--brand-60)`),
    bgDisabled: unsafeCSS(`var(--brand-100)`),
    color: unsafeCSS(`var(--white)`),
    border: unsafeCSS(`none`),
    fill: unsafeCSS(`var(--white)`),
    fillActive: unsafeCSS(`var(--white)`),
    fillDisabled: unsafeCSS(`var(--white)`)
  },
  secondary: {
    bg: unsafeCSS(`var(--neutral-20)`),
    bgActive: unsafeCSS(`var(--neutral-40)`),
    bgHover: unsafeCSS(`var(--neutral-30)`),
    bgDisabled: unsafeCSS(`var(--neutral-10)`),
    color: unsafeCSS(`var(--neutral-90)`),
    border: unsafeCSS(`none`),
    fill: unsafeCSS(`var(--neutral-90)`),
    fillActive: unsafeCSS(`var(--neutral-90`),
    fillDisabled: unsafeCSS(`var(--neutral-40)`)
  },
  tertiary: {
    bg: unsafeCSS(`var(--white)`),
    bgActive: unsafeCSS(`var(--brand-50)`),
    bgHover: unsafeCSS(`rgba(255, 255, 255, 0.8)`),
    bgDisabled: unsafeCSS(`var(--neutral-10)`),
    color: unsafeCSS(`var(--neutral-90)`),
    border: unsafeCSS(`none`),
    fill: unsafeCSS(`var(--neutral-90)`),
    fillActive: unsafeCSS(`var(--white)`),
    fillDisabled: unsafeCSS(`var(--neutral-60)`)
  },
  'outline-primary': {
    bg: unsafeCSS(`transparent`),
    bgActive: unsafeCSS(`var(--brand-70)`),
    fgColorHover: unsafeCSS(`var(--brand-60)`),
    bgDisabled: unsafeCSS(`var(--brand-100)`),
    color: unsafeCSS(`var(--brand-50)`),
    border: unsafeCSS(`2px solid var(--brand-50)`),
    fill: unsafeCSS(`var(--brand-50)`),
    fillActive: unsafeCSS(`var(--brand-50)`),
    fillDisabled: unsafeCSS(`var(--white)`)
  },
  'outline-secondary': {
    bg: unsafeCSS(`transparent`),
    bgActive: unsafeCSS(`var(--brand-70)`),
    fgColorHover: unsafeCSS(`var(--neutral-10)`),
    bgDisabled: unsafeCSS(`var(--brand-100)`),
    color: unsafeCSS(`var(--white)`),
    border: unsafeCSS(`2px solid var(--white)`),
    fill: unsafeCSS(`var(--white)`),
    fillActive: unsafeCSS(`var(--white)`),
    fillDisabled: unsafeCSS(`var(--white)`)
  },
  'link-primary': {
    bg: unsafeCSS(`transparent`),
    bgActive: unsafeCSS(`var(--white)`),
    bgHover: unsafeCSS(`transparent`),
    bgDisabled: unsafeCSS(`var(--neutral-10)`),
    color: unsafeCSS(`var(--neutral-90)`),
    border: unsafeCSS(`none`),
    fill: unsafeCSS(`var(--neutral-90)`),
    fillActive: unsafeCSS(`var(--white)`),
    fillDisabled: unsafeCSS(`var(--neutral-90)`)
  },
  'link-secondary': {
    bg: unsafeCSS(`transparent`),
    bgActive: unsafeCSS(`transparent`),
    bgHover: unsafeCSS(`transparent`),
    bgDisabled: unsafeCSS(`transparent`),
    color: unsafeCSS(`var(--white)`),
    border: unsafeCSS(`none`),
    fill: unsafeCSS(`var(--white)`),
    fillActive: unsafeCSS(`var(--white)`),
    fillDisabled: unsafeCSS(`var(--white)`)
  }
};

function genBaseButtonStyles(): CSSResult {
  return css`
    :host {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 48px;
      font-weight: 700;
      letter-spacing: -0.02em;
      white-space: nowrap;
      box-sizing: border-box;
      outline: none;
      cursor: pointer;
      transition: 0.1s ease-in-out;
      -webkit-tap-highlight-color: transparent;
    }

    :host(.is-disabled) {
      pointer-events: none;
      opacity: 0.5;
    }

    :host(.fill-space) {
      width: 100%;
    }

    .icon {
      flex-shrink: 0;
    }
  `;
}

function genButtonSizeStyles([cssClass, stylesValues]: [ButtonSizeKind, ButtonSizeStylesConfig]): CSSResult {
  return css`
    :host(.${unsafeCSS(cssClass)}) {
      height: ${stylesValues.size};
      padding: 0 ${stylesValues.px};
      font-size: ${stylesValues.fs};
      border-radius: ${stylesValues.radius};
    }

    :host(.${unsafeCSS(cssClass)}.icon-only) {
      width: ${stylesValues.size};
      padding: 0;
    }

    :host(.${unsafeCSS(cssClass)}) .icon {
      --icon-width: var(--btn-icon-size, ${stylesValues.iconSize});
      margin-right: ${stylesValues.iconMargin};
    }
    :host(.${unsafeCSS(cssClass)}.icon-only) .icon {
      margin: 0;
    }
  `;
}

function genButtonVariantStyles([cssClass, stylesValues]: [ButtonVariantKind, ButtonVariantStylesConfig]): CSSResult {
  const isOutlineVariant: boolean = cssClass.includes('outline');

  const hoverStyle: string = isOutlineVariant
    ? `border-color: ${stylesValues.fgColorHover}; color: ${stylesValues.fgColorHover};`
    : `background-color: ${stylesValues.bgHover};`;

  return css`
    :host(.${unsafeCSS(cssClass)}) {
      --fill-1: ${stylesValues.fill};
      background-color: ${stylesValues.bg};
      color: ${stylesValues.color};
      border: ${stylesValues.border};
    }

    @media (hover: hover) {
      :host(.${unsafeCSS(cssClass)}:hover) {
        ${unsafeCSS(hoverStyle)};
      }
    }

    :host(.${unsafeCSS(cssClass)}.is-active) {
      background-color: ${stylesValues.bgActive};
    }

    :host(.${unsafeCSS(cssClass)}.is-active) {
      --fill-1: ${stylesValues.fillActive};
    }

    :host(.${unsafeCSS(cssClass)}.is-disabled) {
      --fill-1: ${stylesValues.fillDisabled};
    }

    :host(.${unsafeCSS(cssClass)}.indicator)::after {
      content: ' ';
      position: absolute;
      top: 2px;
      right: 2px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: var(--brand-50);
    }
  `;
}

export const buttonStyles = (): CSSResultGroup => {
  return [
    genBaseButtonStyles(),
    ...entriesTyped<ButtonSizeKind, ButtonSizeStylesConfig>(buttonSizesConfig).map(genButtonSizeStyles),
    ...entriesTyped<ButtonVariantKind, ButtonVariantStylesConfig>(buttonVariantsConfig).map(genButtonVariantStyles)
  ];
};
