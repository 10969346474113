import { device } from '@bcflit-device';
import { ButtonElement } from '@bcflit-ds-components/base/button/button.element';
import { SvgIconElement } from '@bcflit-ds-components/base/svg-icon/svg-icon.element';
import { TypographySizeKind } from '@bcflit-ds-components/base/typography/types';
import { TypographyElement } from '@bcflit-ds-components/base/typography/typography.element';
import { AlertIconElement } from '@bcflit-ds-components/composed/alert-icon/alert-icon.element';
import { ColumnElement } from '@bcflit-ds-components/utility/column/column.element';
import { RowElement } from '@bcflit-ds-components/utility/row/row.element';
import { msg } from '@lit/localize';
import { ImportedElement, TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { CSSResultGroup, TemplateResult, html } from 'lit';
import { maintenanceStyles } from './maintenance.styles';

function getHeadingFontSize(): TypographySizeKind {
  if (device === 'mobile') {
    return 24;
  }
  return 36;
}

export class MaintenanceElement extends TmfLitElement {
  public static override elementSelector: string = 'tmf-jzm-maintenance';
  public static override styles: CSSResultGroup = maintenanceStyles();
  public override _importedElements: ImportedElement[] = [
    ColumnElement,
    RowElement,
    ButtonElement,
    TypographyElement,
    SvgIconElement,
    AlertIconElement
  ];
  private _headingFontSize: TypographySizeKind = getHeadingFontSize();

  public override render(): TemplateResult {
    if (window.location.href.includes('/pl/')) {
      return html`
        <tmfds-column gap="5" alignItems="center">
          <img class="image" src="/assets/images/560x380-kingspin-janusz.png" />
          <tmfds-alert-icon
            kind="error"
            .heading="${msg('Janusz jest obecnie na wakacjach ze swoją ukochaną Halyną.')}"
            .message="${`Podczas jego nieobecności zespół Januszcasino Development pracuje nad nową i ulepszoną stroną, która podniesie poziom Twoich wrażeń z gry. 
            Bądź na bieżąco z nadchodzącymi wielkimi niespodziankami i nowymi funkcjami.
            Zapraszamy Cię do odkrycia jego siostrzanej marki, <a href="https://kingspin1.io">Kingspin</a>. To Twoja szansa na nowe i wyjątkowe doświadczenia!
                        Masz pytania? Nasz zespół wsparcia klienta jest tu, aby Ci pomóc! Skontaktuj się z nami na <a mailto="support@januszcasino.com">support@januszcasino.com</a>`}"
            buttonVariant="primary"
            buttonLabel="Kingspin Casino"
            @doOnButtonClick="${(): void => {
              window.location.href = 'https://kingspin1.io';
            }}"
          ></tmfds-alert-icon>
        </tmfds-column>
      `;
    }
    return html`
      <tmfds-column gap="5" alignItems="center">
        <img class="image" src="/assets/images/560x380-kingspin-janusz.png" />
        <tmfds-alert-icon
          kind="error"
          .heading="${msg('Janusz is currently on a holiday with his beloved Halyna.')}"
          .message="${`While he is away, the Januszcasino Development team is working on a new and improved website that will elevate your gaming experience. 
              Stay tuned for big surprises and new features.
              Janusz is currently on holiday, but he’s excited to invite you to experience his sister brand, <a href="https://kingspin1.io">Kingspin</a>.
              Don’t miss the chance to explore something new and exceptional!
              If you have any questions, please email our customer support team at <a mailto="support@januszcasino.com">support@januszcasino.com</a>`}"
          buttonVariant="primary"
          buttonLabel="Kingspin Casino"
          @doOnButtonClick="${(): void => {
            window.location.href = 'https://kingspin1.io';
          }}"
        ></tmfds-alert-icon>
      </tmfds-column>
    `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmf-jzm-maintenance': MaintenanceElement;
  }
}
