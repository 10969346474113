import { SvgIconAppComputedStylesService } from '../svg-icon-app-computed-styles.service';
import { SvgIconConfig, SvgIconFill1Color, SvgIconFill2Color } from '../types';

export function getParsedImgSvgPath(
  svgIconConfig: SvgIconConfig,
  svgIconAppComputedStylesService: SvgIconAppComputedStylesService,
  name: string,
  fill1: SvgIconFill1Color | undefined,
  fill2: SvgIconFill2Color | undefined,
  fill1Extracted: string | undefined,
  fill2Extracted: string | undefined
): string | undefined {
  const params: URLSearchParams = new URLSearchParams();
  params.append('brand', svgIconConfig.brand);
  params.append('name', name);

  const fill1Color: string | undefined = parseColorVar(
    svgIconAppComputedStylesService,
    fill1,
    fill1Extracted,
    'fill-1'
  );
  if (fill1Color) {
    params.append('fill1', fill1Color);
  }

  const fill2Color: string | undefined = parseColorVar(
    svgIconAppComputedStylesService,
    fill2,
    fill2Extracted,
    'fill-2'
  );
  if (fill2Color) {
    params.append('fill2', fill2Color);
  }

  return params.toString();
}

function parseColorVar(
  svgIconAppComputedStylesService: SvgIconAppComputedStylesService,
  color: SvgIconFill1Color | SvgIconFill2Color | undefined,
  colorExtracted: string | undefined,
  fillType: string
): string | undefined {
  if (colorExtracted) {
    return colorExtracted;
  }

  if (!color) {
    return undefined;
  }

  let cssVar: string | undefined;

  if (fillType === 'fill-1') {
    switch (color) {
      case 'brand':
        cssVar = 'brand-50';
        break;
      case 'white':
        break;
      case 'success':
        cssVar = 'success-50';
        break;
      default:
        cssVar = color;
    }
  }

  if (fillType === 'fill-2') {
    switch (color) {
      case 'brand':
        cssVar = 'brand-70';
        break;
      case 'white':
      case 'neutral-90':
        cssVar = color;
        break;
      default:
        break;
    }
  }

  if (!cssVar) {
    return undefined;
  }

  return svgIconAppComputedStylesService.getCssVarValue(cssVar);
}
