import { entriesTyped } from '@bcf-vanilla-ts-v1-shared/misc/pure-utils/entries-typed';
import { CSSResult, CSSResultGroup, css, unsafeCSS } from 'lit';
import { AlertIconVariantStylesConfig } from './style.types';
import { AlertIconVariantKind } from './types';

const alertVariantsConfig: Record<AlertIconVariantKind, AlertIconVariantStylesConfig> = {
  success: {
    bg: unsafeCSS(`var(--success-10)`),
    fg: unsafeCSS(`var(--success-50)`)
  },
  warning: {
    bg: unsafeCSS(`var(--warning-10)`),
    fg: unsafeCSS(`var(--warning-50)`)
  },
  error: {
    bg: unsafeCSS(`var(--brand-10)`),
    fg: unsafeCSS(`var(--brand-50)`)
  },
  info: {
    bg: unsafeCSS(`var(--info-10)`),
    fg: unsafeCSS(`var(--info-50)`)
  }
};

function genBaseAlertIconStyle(): CSSResult {
  return css`
    :host {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      gap: var(--space-6);
      width: 100%;
      -webkit-tap-highlight-color: transparent;
      box-sizing: border-box;
    }

    .box-icon {
      border-radius: 50%;
    }

    .details {
      margin-bottom: var(--space-2);
      .body,
      .heading {
        white-space: pre-wrap;
      }
    }
  `;
}

function genAlertIconVariantStyles([cssClass, stylesValues]: [
  AlertIconVariantKind,
  AlertIconVariantStylesConfig
]): CSSResult {
  return css`
    :host(.${unsafeCSS(cssClass)}) {
      --fill-1: ${stylesValues.fg};
    }

    :host(.${unsafeCSS(cssClass)}) .box-icon {
      background-color: ${stylesValues.bg};
    }
  `;
}

export const genAlertIconCommonStyles = (): CSSResultGroup => {
  return [
    genBaseAlertIconStyle(),
    ...entriesTyped<AlertIconVariantKind, AlertIconVariantStylesConfig>(alertVariantsConfig).map(
      genAlertIconVariantStyles
    )
  ];
};
