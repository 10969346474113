/* eslint-disable @typescript-eslint/naming-convention */
import { DiToken } from '@bcf-vanilla-ts-v1-shared/di/di-token';

export const APP_HOSTNAME: DiToken<string> = new DiToken<string>('appHostname');

export const LOCAL_STORAGE: DiToken<Storage> = new DiToken<Storage>('localStorage');

export const APP_SERVER_REQ_URL: DiToken<string> = new DiToken<string>('appServerReqUrl');

export const APP_SERVER_REQ_PROTOCOL: DiToken<string> = new DiToken<string>('appServerReqProtocol');

export const APP_SERVER_REQ_PORT: DiToken<string> = new DiToken<string>('appServerReqPort');

export const LOCATION: DiToken<Location> = new DiToken<Location>('location');

export const APP_WITH_WORKER: DiToken<boolean> = new DiToken<boolean>('appWithWorker');
