import { device } from '@bcflit-device';
import { SvgIconName } from '@bcflit-ds-components/base/svg-icon/icons-types';
import { SvgIconElement } from '@bcflit-ds-components/base/svg-icon/svg-icon.element';
import { TypographySizeKind } from '@bcflit-ds-components/base/typography/types';
import { TypographyElement } from '@bcflit-ds-components/base/typography/typography.element';
import { ColumnElement } from '@bcflit-ds-components/utility/column/column.element';
import { ImportedElement, TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { CSSResultGroup, TemplateResult, css, html } from 'lit';
import { property } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { when } from 'lit/directives/when.js';
import { ButtonElement } from '../../base/button/button.element';
import { ButtonSizeKind } from '../../base/button/types';
import { alertIconStylesDesktop } from './alert-icon.desktop.styles';
import { alertIconStylesMobile } from './alert-icon.mobile.styles';
import { AlertIconVariantKind } from './types';

function stylesSelector(): CSSResultGroup {
  if (device === 'mobile') {
    return alertIconStylesMobile();
  }
  if (device === 'desktop') {
    return alertIconStylesDesktop();
  }
  return css``;
}

function getButtonSize(): ButtonSizeKind {
  if (device === 'desktop') {
    return 'large';
  }
  return 'medium';
}
function getHeadingFontSize(): TypographySizeKind {
  if (device === 'mobile') {
    return 16;
  }
  return 20;
}
function getBodyFontSize(): TypographySizeKind {
  if (device === 'mobile') {
    return 14;
  }
  return 16;
}

export class AlertIconElement extends TmfLitElement {
  public static override elementSelector: string = 'tmfds-alert-icon';
  public static override styles: CSSResultGroup = stylesSelector();
  public override _importedElements: ImportedElement[] = [
    TypographyElement,
    ColumnElement,
    SvgIconElement,
    ButtonElement
  ];

  private _currentCssClasses: string | undefined;
  private _buttonSize: ButtonSizeKind = getButtonSize();
  private _headingFontSize: TypographySizeKind = getHeadingFontSize();
  private _bodyFontSize: TypographySizeKind = getBodyFontSize();
  constructor() {
    super();
    this._registerStylesFroSnapshots(AlertIconElement);
  }

  @property({ type: String })
  public kind: AlertIconVariantKind = 'success';

  @property({ type: String })
  public icon?: SvgIconName;

  @property({ type: String })
  public heading?: string;

  @property({ type: String })
  public message?: string | TemplateResult;

  @property({ type: String })
  public buttonLabel?: string;

  @property({ type: String })
  public buttonVariant: 'primary' | 'outline-primary' = 'outline-primary';

  private _updateCssClasses(): void {
    this._currentCssClasses ??= this.className;
    const cssClasses: string[] = [this._currentCssClasses];
    this.className = cssClasses.join(' ');
  }

  private _buttonClick(): void {
    this.dispatchEvent(new Event('doOnButtonClick'));
  }

  public override render(): TemplateResult {
    this._updateCssClasses();

    return html`
      ${when(
        this.icon,
        () =>
          html` <tmfds-column justify="center" alignItems="center" class="box-icon">
            <tmfds-svg-icon class="custom-size" .name="${this.icon!}"></tmfds-svg-icon>
          </tmfds-column>`
      )}
      <div class="details">
        ${when(
          this.heading,
          () => html`
            <tmfds-typography
              .size="${this._headingFontSize}"
              variant="heading"
              textAlign="center"
              .uppercase="${true}"
            >
              ${this.heading}
            </tmfds-typography>
          `
        )}
        ${when(
          this.message,
          () =>
            html`<tmfds-typography .size="${this._bodyFontSize}" variant="body" textAlign="center">
              ${unsafeHTML(this.message)}
            </tmfds-typography>`
        )}
      </div>
      ${when(
        this.buttonLabel,
        () =>
          html`<tmfds-button .variant="${this.buttonVariant}" .size="${this._buttonSize}" @click="${this._buttonClick}"
            >${this.buttonLabel}</tmfds-button
          >`
      )}
    `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmfds-alert-icon': AlertIconElement;
  }
}
