import { css, CSSResult } from 'lit';

export const appStyles: CSSResult = css`
  :host {
    display: block;
    width: 100%;
    height: 100vh;
    min-height: -webkit-fill-available;
    background-color: var(--white);
    font-family: 'Poppins';
  }
`;

export const appVariables: CSSResult = css`
  :host {
    /* BASIC */
    --white: #fff;
    --black: #000;

    /* WHITE */
    --white-0: hsl(0, 0%, 100%, 0);
    --white-10: hsl(0, 0%, 100%, 0.1);
    --white-20: hsl(0, 0%, 100%, 0.2);
    --white-30: hsl(0, 0%, 100%, 0.3);
    --white-40: hsl(0, 0%, 100%, 0.4);
    --white-50: hsl(0, 0%, 100%, 0.5);
    --white-60: hsl(0, 0%, 100%, 0.6);
    --white-70: hsl(0, 0%, 100%, 0.7);
    --white-80: hsl(0, 0%, 100%, 0.8);
    --white-90: hsl(0, 0%, 100%, 0.9);
    --white-100: hsl(0, 0%, 100%);

    /* BLACK */
    --black-0: hsl(0, 0%, 4%, 0);
    --black-10: hsl(0, 0%, 4%, 0.1);
    --black-20: hsl(0, 0%, 4%, 0.2);
    --black-30: hsl(0, 0%, 4%, 0.3);
    --black-40: hsl(0, 0%, 4%, 0.4);
    --black-50: hsl(0, 0%, 4%, 0.5);
    --black-60: hsl(0, 0%, 4%, 0.6);
    --black-70: hsl(0, 0%, 4%, 0.7);
    --black-80: hsl(0, 0%, 4%, 0.8);
    --black-90: hsl(0, 0%, 4%, 0.9);
    --black-100: hsl(0, 0%, 4%);

    /* NEUTRAL */
    --neutral-0: hsl(0deg, 0%, 4%, 0);
    --neutral-10: #f0f0f0;
    --neutral-20: #d6d6d6;
    --neutral-30: #bdbdbd;
    --neutral-40: #a3a3a3;
    --neutral-50: #8a8a8a;
    --neutral-60: #707070;
    --neutral-70: #575757;
    --neutral-80: #242424;
    --neutral-90: #0a0a0a;
    --neutral-100: hsl(0deg, 0%, 4%, 0.1);

    /* BRAND */
    --brand-0: hsl(0deg, 92%, 56%, 0);
    --brand-10: #feebeb;
    --brand-20: #fcbbbb;
    --brand-30: #fa8a8a;
    --brand-40: #f85959;
    --brand-50: #f62828;
    --brand-60: #e10a0a;
    --brand-70: #b00808;
    --brand-80: #7f0606;
    --brand-90: #4e0303;
    --brand-100: hsl(0deg, 92%, 56%, 0.1);

    /* BRAND */
    --accent-0: hsl(0deg, 92%, 56%, 0);
    --accent-10: #feebeb;
    --accent-20: #fcbbbb;
    --accent-30: #fa8a8a;
    --accent-40: #f85959;
    --accent-50: #f62828;
    --accent-60: #e10a0a;
    --accent-70: #b00808;
    --accent-80: #7f0606;
    --accent-90: #4e0303;
    --accent-100: hsl(0deg, 92%, 56%, 0.1);

    /* SUCCESS */
    --success-0: hsl(122, 39%, 49%, 0);
    --success-10: #dbefdc;
    --success-20: #b7dfb9;
    --success-30: #94cf96;
    --success-40: #70bf73;
    --success-50: #4caf50;
    --success-60: #3d8c40;
    --success-70: #2e6930;
    --success-80: #1e4620;
    --success-90: #0f2310;
    --success-100: hsl(122, 39%, 49%, 0.1);

    /* WARNING */
    --warning-0: hsl(36, 100%, 50%, 0);
    --warning-10: #ffeacc;
    --warning-20: #ffd699;
    --warning-30: #ffc166;
    --warning-40: #ffad33;
    --warning-50: #ff9800;
    --warning-60: #cc7a00;
    --warning-70: #995b00;
    --warning-80: #663d00;
    --warning-90: #331e00;
    --warning-100: hsl(36, 100%, 50%, 0.1);

    /* INFO */
    --info-0: hsl(235, 100%, 60%, 0);
    --info-10: #d6daff;
    --info-20: #adb4ff;
    --info-30: #858fff;
    --info-40: #5c69ff;
    --info-50: #3344ff;
    --info-60: #2936cc;
    --info-70: #1f2999;
    --info-80: #141b66;
    --info-90: #0a0e33;
    --info-100: hsl(235, 100%, 60%, 0.1);

    /* BORDER COLOR */
    --border-transparent-20: rgba(10, 10, 10, 0.2);

    /* RADIUSES */
    --radius-1: 8px;
    --radius-2: 12px;
    --radius-3: 16px;
    --radius-4: 24px;
    --radius-5: 32px;
    --radius-6: 40px;
    --radius-7: 48px;

    /* SPACING */
    --space-1: 4px;
    --space-2: 8px;
    --space-3: 12px;
    --space-4: 16px;
    --space-5: 20px;
    --space-6: 24px;
    --space-7: 32px;
    --space-8: 40px;
    --space-9: 48px;
    --space-10: 56px;
    --space-11: 64px;
    --space-12: 80px;
    --space-13: 96px;
    --space-14: 128px;
    --space-15: 160px;
    --space-16: 192px;
    --space-17: 224px;
    --space-18: 256px;
    --space-19: 512px;

    /* IOS */
    --safe-area-top: env(safe-area-inset-top);
    --safe-area-bottom: env(safe-area-inset-bottom);
    --safe-area-left: env(safe-area-inset-left);
    --safe-area-right: env(safe-area-inset-right);

    /* SIZES */
    --header-height: calc(56px + var(--safe-area-top) + var(--top-space-height, 0px));
    --bottom-nav-height: calc(60px + var(--safe-area-bottom));

    /* SIZES */
    --desktop-center-size: 1330px;

    /*SEMANTIC COLORS*/

    /* Background */
    --background-body: var(--neutral-10);
    --background-secondary: var(--neutral-10);
    --background-brand: var(--brand-50);
    --background-accent: var(--accent-50);
    --background-state-brand: var(--brand-50);
    --background-state-brand-subtle: var(--brand-10);
    --background-state-accent: var(--accent-50);
    --background-state-accent-subtle: var(--accent-10);
    --background-state-success: var(--success-50);
    --background-state-success-subtle: var(--success-10);
    --background-state-warning: var(--warning-50);
    --background-state-warning-subtle: var(--warning-10);
    --background-state-info: var(--info-50);
    --background-state-info-subtle: var(--info-10);
    --background-state-error: var(--error-50);
    --background-state-error-subtle: var(--error-10);
    --background-primary: var(--white-100);
    --background-tertiary: var(--neutral-20);
    --background-quaternary: var(--neutral-30);
    --background-primary-inverse: var(--neutral-90);

    /* Border */
    --border-state-success: var(--success-50);
    --border-state-error: var(--error-50);
    --border-state-warning: var(--warning-50);
    --border-state-info: var(--info-50);
    --border-brand: var(--brand-50);
    --border-accent: var(--accent-50);
    --border-primary: var(--neutral-20);
    --border-secondary: var(--neutral-90);

    /* Text */
    --text-primary: var(--neutral-90);
    --text-primary-inverse: var(--white-100);
    --text-secondary: var(--neutral-70);
    --text-tertiary: var(--neutral-60);
    --text-quaternary: var(--neutral-50);
    --text-brand: var(--brand-50);
    --text-link: var(--brand-50);
    --text-link-hover: var(--brand-60);
    --text-link-disabled: var(--neutral-70);
    --text-state-success: var(--success-50);
    --text-state-error: var(--error-50);
    --text-state-warning: var(--warning-50);
    --text-state-info: var(--info-50);
    --text-on-dark-image: var(--white-100);
    --text-on-light-image: var(--neutral-90);
    --text-accent: var(--brand-50);

    /* Fonts */
    --font-family-poppins: Poppins;
    --font-family-paytone-one: Paytone One;

    /* Icon */
    --icon-primary: var(--neutral-90);
    --icon-primary-inverse: var(--white-100);
    --icon-secondary: var(--neutral-70);
    --icon-brand: var(--brand-50);
    --icon-brand-subtle: var(--brand-10);
    --icon-accent: var(--accent-50);
    --icon-accent-subtle: var(--accent-10);
    --icon-state-success: var(--success-50);
    --icon-state-error: var(--error-50);
    --icon-state-warning: var(--warning-50);
    --icon-state-info: var(--info-50);
    --icon-state-success-subtle: var(--success-10);
    --icon-state-error-subtle: var(--error-10);
    --icon-state-warning-subtle: var(--warning-10);
    --icon-state-info-subtle: var(--info-10);
    --icon-secondary-subtle: var(--neutral-20);

    /* Components - Input */
    --components-input-fg-primary: var(--text-primary);
    --components-input-fg-secondary: var(--text-secondary);
    --components-input-fg-error: var(--text-state-error);
    --components-input-fg-disabled: var(--text-tertiary);
    --components-input-bg-disabled: var(--background-secondary);
    --components-input-border: var(--border-primary);
    --components-input-border-focus: var(--border-secondary);
    --components-input-border-hover: var(--neutral-30);

    /* Components - Dropdown */
    --components-dropdown-text: var(--text-primary);
    --components-dropdown-text-disabled: var(--text-tertiary);
    --components-dropdown-text-active: var(--text-brand);
    --components-dropdown-bg: var(--background-primary);
    --components-dropdown-bg-hover: var(--background-secondary);

    /* Components - Toggles */
    --components-toggles-fg: var(--icon-primary-inverse);
    --components-toggles-border: var(--border-secondary);
    --components-toggles-disabled: var(--neutral-40);
    --components-toggles-bg-active: var(--background-primary-inverse);
    --components-toggles-switch-fg-diabled: var(--neutral-10);
    --components-toggles-switch-bg-selected-disabled: var(--neutral-40);
    --components-toggles-switch-bg: var(--background-tertiary);

    /* Components - Scroll */
    --components-scroll-bg: var(--neutral-20);
    --components-scroll-bg-hover: var(--neutral-30);

    /* Components - Footer */
    --components-footer-bg: var(--background-primary);
    --components-footer-text: var(--text-quaternary);
    --components-footer-border: var(--border-primary);

    /* Components - Bottom Bar */
    --components-bottom-bar-bg: var(--background-primary-inverse);
    --components-bottom-bar-fg: var(--neutral-50);
    --components-bottom-bar-fg-active: var(--text-primary-inverse);
    --components-bottom-bar-bg-button: var(--background-brand);
    --components-bottom-bar-fg-button: var(--icon-primary-inverse);

    /* Components - Button */
    --components-button-primary-fg: var(--text-primary-inverse);
    --components-button-primary-bg: var(--background-brand);
    --components-button-primary-bg-hover: var(--brand-60);
    --components-button-secondary-fg: var(--text-brand);
    --components-button-secondary-bg: var(--white-0);
    --components-button-secondary-border: var(--border-brand);
    --components-button-secondary-border-hover: var(--brand-60);
    --components-button-secondary-fg-hover: var(--brand-60);
    --components-button-tertiary-fg: var(--text-primary);
    --components-button-tertiary-bg: var(--background-primary);
    --components-button-tertiary-bg-hover: var(--background-secondary);
    --components-button-quatenary-fg: var(--text-primary);
    --components-button-quatenary-bg: var(--background-tertiary);
    --components-button-quatenary-bg-hover: var(--background-quaternary);

    /* Components - Hamburger */
    --components-hamburger-hamburger-nav-bg-hover: var(--background-secondary);
    --components-hamburger-hamburger-nav-bg-active: var(--background-secondary);
    --components-hamburger-hamburger-nav-icon: var(--text-primary);
    --components-hamburger-hamburger-nav-text-secondary: var(--text-secondary);
    --components-hamburger-hamburger-nav-text-primary-active: var(--text-primary);
    --components-hamburger-hamburger-nav-text-disabled: var(--text-quaternary);
    --components-hamburger-bg-primary: var(--background-primary);
    --components-hamburger-bg-secondary: var(--background-secondary);
    --components-hamburger-hamburger-nav-text-primary: var(--text-primary);
    --components-hamburger-hamburger-nav-icon-hover: var(--text-primary);
    --components-hamburger-hamburger-nav-icon-active: var(--text-brand);
    --components-hamburger-hamburger-nav-icon-disabled: var(--text-quaternary);
    --components-hamburger-hamburger-nav-text-primary-hover: var(--text-primary);
    --components-hamburger-hamburger-nav-border: var(--border-primary);

    /* Components - Sidebar */
    --components-sidebar-bg-body: var(--background-primary);
    --components-sidebar-panel-bg-panel: var(--background-primary-inverse);
    --components-sidebar-panel-bg-icon: var(--background-brand);
    --components-sidebar-panel-icon: var(--icon-primary-inverse);
    --components-sidebar-panel-fg-primary: var(--text-primary-inverse);
    --components-sidebar-panel-fg-secondary: var(--text-quaternary);
    --components-sidebar-sidebar-nav-icon: var(--text-primary);
    --components-sidebar-sidebar-nav-icon-hover: var(--text-primary);
    --components-sidebar-sidebar-nav-icon-active: var(--text-brand);
    --components-sidebar-sidebar-nav-icon-disabled: var(--text-quaternary);
    --components-sidebar-sidebar-nav-text-primary: var(--text-primary);
    --components-sidebar-sidebar-nav-text-secondary: var(--text-secondary);
    --components-sidebar-sidebar-nav-text-primary-hover: var(--text-primary);
    --components-sidebar-sidebar-nav-text-primary-active: var(--text-primary);
    --components-sidebar-sidebar-nav-text-disabled: var(--text-quaternary);
    --components-sidebar-sidebar-nav-bg-hover: var(--background-secondary);
    --components-sidebar-sidebar-nav-bg-active: var(--background-secondary);
    --components-sidebar-sidebar-nav-border: var(--border-primary);

    /* Components - Modals */
    --components-modals-fg-header-inverse: var(--text-primary-inverse);
    --components-modals-bg-header: var(--background-primary);
    --components-modals-bg-body: var(--background-primary);
    --components-modals-bg-footer: var(--background-secondary);
    --components-modals-fg-header: var(--text-primary);
    --components-modals-bg-header-inverse: var(--background-brand);
    --components-modals-bg-overlay: var(--black-80);

    /* Components - Tooltips */
    --components-tootlips-text: var(--text-primary-inverse);
    --components-tootlips-bg: var(--background-primary-inverse);

    /* Components - Tag */
    --components-tag-text: var(--text-primary);
    --components-tag-text-active: var(--text-primary-inverse);
    --components-tag-text-disabled: var(--neutral-40);
    --components-tag-bg: var(--background-tertiary);
    --components-tag-bg-hover: var(--background-quaternary);
    --components-tag-bg-active: var(--background-primary-inverse);
    --components-tag-bg-disabled: var(--background-secondary);

    /* Components - Tab */
    --components-tab-text: var(--text-primary);
    --components-tab-text-active: var(--text-brand);
    --components-tab-text-disabled: var(--neutral-30);
    --components-tab-border: var(--border-primary);
    --components-tab-border-active: var(--border-brand);
    --components-tab-border-hover: var(--border-secondary);
    --components-tab-border-disabled: var(--border-primary);

    /* Components - Badges */
    --components-badges-badge-tag-fg-neutral: var(--text-primary-inverse);
    --components-badges-badge-tag-bg-neutral: var(--black-60);
    --components-badges-badge-tag-fg-neutral-invert: var(--text-primary);
    --components-badges-badge-tag-bg-neutral-invert: var(--background-tertiary);
    --components-badges-badge-tag-fg-primary: var(--text-primary-inverse);
    --components-badges-badge-tag-bg-primary: var(--background-brand);
    --components-badges-badge-tag-fg-primary-invert: var(--text-brand);
    --components-badges-badge-tag-bg-primary-invert: var(--background-state-brand-subtle);
    --components-badges-badge-tag-fg-success: var(--text-primary-inverse);
    --components-badges-badge-tag-bg-success: var(--background-state-success);
    --components-badges-badge-tag-fg-success-invert: var(--text-state-success);
    --components-badges-badge-tag-bg-success-invert: var(--background-state-success-subtle);
    --components-badges-badge-tag-fg-waring: var(--text-primary-inverse);
    --components-badges-badge-tag-bg-waring: var(--background-state-warning);
    --components-badges-badge-tag-fg-waring-invert: var(--text-state-warning);
    --components-badges-badge-tag-bg-waring-invert: var(--background-state-warning-subtle);
    --components-badges-badge-tag-fg-info: var(--text-primary-inverse);
    --components-badges-badge-tag-bg-info: var(--background-state-info);
    --components-badges-badge-tag-fg-info-invert: var(--text-state-info);
    --components-badges-badge-tag-bg-info-invert: var(--background-state-info-subtle);
    --components-badges-badge-tag-fg-error: var(--text-primary-inverse);
    --components-badges-badge-tag-bg-error: var(--background-state-error);
    --components-badges-badge-tag-fg-error-invert: var(--text-state-error);
    --components-badges-badge-tag-bg-error-invert: var(--background-state-error-subtle);
    --components-badges-badge-fg-success: var(--text-state-success);
    --components-badges-badge-bg-success: var(--background-state-success-subtle);
    --components-badges-badge-fg-warning: var(--text-state-warning);
    --components-badges-badge-bg-warning: var(--background-state-warning-subtle);
    --components-badges-badge-fg-error: var(--text-state-error);
    --components-badges-badge-bg-error: var(--background-state-error-subtle);
    --components-badges-badge-fg-info: var(--text-state-info);
    --components-badges-badge-bg-info: var(--background-state-info-subtle);
  }

  @media screen and (max-width: 1678px) {
    :host {
      --desktop-center-size: 100%;
    }
  }
`;
