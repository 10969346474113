import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { resolveUrlWithVersionNumber } from '@tmf-logic-api-access/common/resolve-url-with-version-number';
import { SvgIconConfig } from './types';

/** without this we render same image every time and it may be visually blinking on screen.
 * this way we always use same image on page without load new url in requests
 */

export class SvgIconLoader {
  private _memory: Record<string, HTMLImageElement[]> = {};
  private _imagesInUse: WeakSet<HTMLImageElement> = new WeakSet<HTMLImageElement>();

  public loadAndLock(name: string, svgImagePath: string, svgIconConfig: SvgIconConfig): HTMLImageElement {
    if (!this._memory[svgImagePath]) {
      this._memory[svgImagePath] = [];
    }

    const imageNotUsed: HTMLImageElement | undefined = this._memory[svgImagePath].find(
      (image: HTMLImageElement) => !this._imagesInUse.has(image)
    );
    if (imageNotUsed) {
      this._imagesInUse.add(imageNotUsed);
      return imageNotUsed;
    }

    const image: HTMLImageElement = new Image();
    image.alt = name;
    image.src = this.getImageSrc(svgImagePath, svgIconConfig);
    this._memory[svgImagePath].push(image);
    this._imagesInUse.add(image);
    return image;
  }

  public getImageSrc(svgImagePath: string, svgIconConfig: SvgIconConfig): string {
    return `${resolveUrlWithVersionNumber(svgIconConfig.hostUrl)}get-svg-icon/?${svgImagePath}`;
  }

  public unlock(image: HTMLImageElement): void {
    this._imagesInUse.delete(image);
  }
}
export function provideSvgIconLoader(): SvgIconLoader {
  return provideSingleton(SvgIconLoader, () => new SvgIconLoader());
}
