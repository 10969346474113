export function isBrowser(): boolean {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
}

function isServer(): boolean {
  return typeof process !== 'undefined' && process.versions != null && process.versions.node != null;
}

// function isWebWorker(): boolean {
//   return typeof self === 'object' && self.constructor && self.constructor.name === 'DedicatedWorkerGlobalScope';
// }

export function isSSR(): boolean {
  return isServer();
}

export function isPrerender(): boolean {
  return window.navigator.userAgent.includes('Prerender');
}

export function isTestingEnv(): boolean {
  return isServer();
}
