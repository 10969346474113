import { appStyles, appVariables } from '@bcflit-css/janusz-casino/mobile/app.styles';
import { provideSvgIconAppComputedStylesService } from '@bcflit-ds-components/base/svg-icon/svg-icon-app-computed-styles.service';
import { EnvTma } from '@bcflit-env/types';
import { MaintenancePageElement } from '@tmf-pages/casino/janusz-casino/mobile/maintenance/maintenance-page.element';
import { ImportedElement, TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { ActivatedRoute, provideActivatedRoute } from '@tmf-shared-platform/activated-route/activated-route';
import { ActivatedUrl, provideActivatedUrl } from '@tmf-shared-platform/activated-url/activated-url';
import { HistoryUrl, provideHistoryUrl } from '@tmf-shared-platform/history-url/history-url';
import { BcfLitRouter } from '@tmf-shared-platform/router/internal';
import {
  RouterNavigatorService,
  provideRouterNavigatorService
} from '@tmf-shared-platform/router/router-navigator.service';
import { routerPageScrollerService } from '@tmf-shared-platform/router/router-page-scroller.service';
import { CmsSeoJsonLdUpdaterSmartElement } from '@tmf-ui-cms-casino-components-janusz-casino-mobile/cms-seo-json-ld-updater-smart/cms-seo-json-ld-updater-smart.element';
import { CmsSeoMetaUpdaterSmartElement } from '@tmf-ui-cms-casino-components-janusz-casino-mobile/cms-seo-meta-updater-smart/cms-seo-meta-updater-smart.element';
import { GtmLoaderElement } from '@tmf-ui-misc-casino-components-janusz-casino-mobile/gtm-loader/gtml-loader.element';
import { SharedSettings, provideSharedSettings } from '@tmp-shared-settings/settings/shared-settings';
import { CSSResult, TemplateResult, html } from 'lit';
import { property, state } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { appRoutes } from '../../mobile/app/app-routes';

export async function boostrapApplication(env: EnvTma): Promise<void> {
  const sharedSettings: SharedSettings = provideSharedSettings();
  sharedSettings.env = env;
  (await import('../../mobile/app/app-init')).appInit();
  class TmfApp extends TmfLitElement {
    public static override styles: CSSResult[] = [appVariables, appStyles];
    protected override _importedElements: ImportedElement[] = [
      CmsSeoMetaUpdaterSmartElement,
      CmsSeoJsonLdUpdaterSmartElement,
      GtmLoaderElement,
      MaintenancePageElement
    ];

    @property({ attribute: false })
    public router: BcfLitRouter = new BcfLitRouter(
      this,
      appRoutes(() => this.router, provideSharedSettings().env.langs)
    );

    @state()
    private _shouldShowCountryBlockage: boolean = false;

    constructor(
      private _activatedRoute: ActivatedRoute = provideActivatedRoute(),
      private _activatedUrl: ActivatedUrl = provideActivatedUrl(),
      private _historyUrl: HistoryUrl = provideHistoryUrl()
    ) {
      super();
    }

    public override connectedCallback(): void {
      super.connectedCallback();
      const routerNavigatorService: RouterNavigatorService = provideRouterNavigatorService();
      routerNavigatorService.bindRouter(this.router);
      routerNavigatorService.bindHistoryUrl(this._historyUrl);
      routerPageScrollerService.init();
      // provideCookiesConsentService().init();
      // provideGtmNotifierService().init();
      // provideAffiliatesDataCatcher().init(this._activatedRoute);
      // this._checkCountryBlockage();
      // this._checkUserPanelAuthGuard();
      this._activatedRoute.addRouter(this.router);
      provideSvgIconAppComputedStylesService().setComputedStyles(() => getComputedStyle(this));
    }

    // private _checkCountryBlockage(): void {
    //   checkCountryBlockage.shouldShowBlockagePage().subscribe((shouldShow: boolean) => {
    //     this._shouldShowCountryBlockage = shouldShow;
    //     import(
    //       '@tmf-ui-misc-casino-components-janusz-casino-mobile/not-available-in-your-country/not-available-in-your-country.element'
    //       // eslint-disable-next-line @typescript-eslint/typedef
    //     ).then((mod) => registerElement(mod.NotAvailableInYourCountryElement));
    //   });
    // }

    // private _checkUserPanelAuthGuard(): void {
    //   combineLatest([authState.isLoggedIn(), this._activatedUrl.url$])
    //     .pipe(first(([isLoggedIn, url]: [boolean, string]) => !isLoggedIn && url.includes(userSettingsI18nSegment())))
    //     .subscribe(() => routerUpdateUrl(homeRoutePath()));
    // }

    public override render(): TemplateResult {
      return html`<tmf-jzm-maintenance-page></tmf-jzm-maintenance-page>`;
      return html`
        <tmf-jzm-cms-seo-json-ld-updater-smart></tmf-jzm-cms-seo-json-ld-updater-smart>
        <tmf-jzm-cms-seo-meta-updater-smart></tmf-jzm-cms-seo-meta-updater-smart>
        <tmf-jzm-gtm-loader .gtmId="${'GTM-KV8K6H4H'}"></tmf-jzm-gtm-loader>
        ${when(
          this._shouldShowCountryBlockage,
          () => html`<tmf-jzm-not-available-in-your-country></tmf-jzm-not-available-in-your-country>`,
          () => html`${this.router.outlet()}`
        )}
      `;
    }
  }
  customElements.define('tmf-app', TmfApp);
}
