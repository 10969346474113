import { DeviceKind } from '@bcflit-env/types';
import { CmsActionButtonOld } from './cms-action-button';

export type CmsPromotionSlide = {
  readonly title: string;
  readonly text: string;
  readonly imageUrl: string;
  readonly actionButton: CmsActionButtonOld;
  readonly campaignId: string | undefined;
};

function createActionButton(json: Record<string, any>): CmsActionButtonOld {
  return {
    text: json['label'],
    kind: json['type'],
    url: json['payload'] ?? undefined
  };
}

export function createCmsPromotionSlidesFromHttpData(
  jsonArray: Record<string, any>,
  deviceKind: DeviceKind
): CmsPromotionSlide[] {
  const container: CmsPromotionSlide[] = [];
  for (const data of jsonArray['home']) {
    const promotionSlide: CmsPromotionSlide = {
      title: data['title'],
      text: data['content'],
      campaignId: data['campaignId'] ?? undefined,
      imageUrl: deviceKind === 'mobile' && data['imageAlt'] ? data['imageAlt'] : data['image'],
      actionButton: createActionButton(data['buttons'][0])
    };
    container.push(promotionSlide);
  }
  return container;
}
