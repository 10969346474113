import { CSSResultGroup, css } from 'lit';

export const svgIconStyles = (): CSSResultGroup => {
  return css`
    :host {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    :host(.custom-size) img {
      width: var(--icon-width, auto);
      height: var(--icon-height, auto);
    }
  `;
};
