import { EnvCommon } from '@bcflit-env/types';

// // eslint-disable-next-line @typescript-eslint/typedef
export const envBaseCommon: Pick<EnvCommon, 'langs' | 'svgIconConfig' | 'brandInfo'> = {
  langs: ['en', 'pl'],
  svgIconConfig: {
    brand: 'januszcasino',
    hostUrl: 'https://image-assets-server-dot-cms-headless-gae.ew.r.appspot.com/'
  },
  brandInfo: {
    brandName: 'Janusz Casino',
    supportEmail: 'support@januszcasino.com',
    host: 'januszcasino.com'
  }
};
