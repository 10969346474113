import { BcfLitElement } from '@bcflit-v1-ui-shared/classes/bcf-lit-element';

export function registerTestStylesElement(element: typeof BcfLitElement): void {
  const globalAny: any = globalThis;
  if (!globalAny['stylesTest']) {
    globalAny['stylesTest'] = {};
  }
  globalAny['stylesTest'][element.elementSelector] = element.styles!.toString();
}

export function getTestStyles(): string | undefined {
  const globalAny: any = globalThis;
  if (!globalAny['stylesTest']) {
    return undefined;
  }
  const container: string[] = [];
  for (const [selector, styles] of Object.entries(globalAny['stylesTest'])) {
    container.push(`<style module-selector="${selector}">${styles}</style>`);
  }
  return container.join('\n');
}
