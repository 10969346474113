import { SvgIconElement } from '@bcflit-ds-components/base/svg-icon/svg-icon.element';
import { ImportedElement, TmfLitElement } from '@tmf-shared-classes/tmf-lit-element';
import { CSSResultGroup, TemplateResult, html } from 'lit';
import { property } from 'lit/decorators.js';
import { when } from 'lit/directives/when.js';
import { SvgIconName } from '../svg-icon/icons-types';
import { buttonStyles } from './button.styles';
import { ButtonSizeKind, ButtonVariantKind } from './types';

export class ButtonElement extends TmfLitElement {
  public static override elementSelector: string = 'tmfds-button';
  public static override styles: CSSResultGroup = buttonStyles();
  public override _importedElements: ImportedElement[] = [SvgIconElement];

  @property({ type: String })
  public variant!: ButtonVariantKind;

  @property({ type: String })
  public size!: ButtonSizeKind;

  @property({ type: Boolean })
  public fillSpace?: boolean = false;

  @property({ type: Boolean })
  public indicator?: boolean = false;

  @property({ type: String })
  public iconLeft?: SvgIconName;

  @property({ type: String })
  public iconOnly?: SvgIconName;

  @property({ type: Boolean })
  public isDisabled?: boolean = false;

  @property({ type: Boolean })
  public isActive: boolean = false;

  private _currentCssClasses: string | undefined;

  constructor() {
    super();
    this._registerStylesFroSnapshots(ButtonElement);
  }

  private _updateCssClasses(): void {
    this._currentCssClasses ??= this.className;

    const cssClasses: string[] = [this._currentCssClasses, this.variant, this.size];

    if (this.isActive) {
      cssClasses.push('is-active');
    }

    if (this.iconOnly) {
      cssClasses.push('icon-only');
    }

    if (this.isDisabled) {
      cssClasses.push('is-disabled');
    }

    if (this.fillSpace) {
      cssClasses.push('fill-space');
    }
    if (this.indicator) {
      cssClasses.push('indicator');
    }

    this.className = cssClasses.join(' ');
  }

  public override render(): TemplateResult {
    this._updateCssClasses();
    if (this.iconOnly) {
      return html`<tmfds-svg-icon class="custom-size" .name="${this.iconOnly}"></tmfds-svg-icon>`;
    }

    return html`${when(
        this.iconLeft,
        () => html`<tmfds-svg-icon class="custom-size" .name="${this.iconLeft!}"></tmfds-svg-icon>`
      )} <slot></slot>`;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'tmfds-button': ButtonElement;
  }
}
